import { Stack, Tabs, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { _t } from 'lang';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import UserSelect from 'components/selects/UserSelect';
import FormRow from 'components/forms/FormRow';
import { useSearchParams } from 'react-router-dom';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from '../FormFooter';
import PermissionRow from './PermissionRow';
import PermissionTable from './PermissionTable';

/** @typedef {'viewProjects' | 'createTimeLogs' | 'createComments' | 'uploadFiles' | 'manageOthersComments' | 'manageOthersTimeLogs' | 'manageProjects' | 'overseeInternalCosts' | 'manageSettings' | 'manageRoles' | 'manageFinance'} IPermissionName */

/**
 * @typedef {{
 *   roleName: string;
 *   description: string;
 *   permissions: Record<IPermissionName, string | null>;
 *   members: string[];
 * }} RoleFormData
 */

/**
 * The initial values for the role form.
 *
 * @type {RoleFormData}
 */
export const ROLE_FORM_INITIAL_VALUES = {
  roleName: '',
  description: '',
  permissions: {
    // Project permissions
    viewProjects: null,
    createTimeLogs: null,
    createComments: null,
    uploadFiles: null,
    // Manage permissions
    manageOthersComments: null,
    manageOthersTimeLogs: null,
    manageProjects: null,
    overseeInternalCosts: null,
    manageFinance: null,
    manageSettings: null,
    manageRoles: null,
  },
  members: [],
};

/**
 * The Add/Edit role form.
 *
 * @param {{
 *   initialValues: RoleFormData;
 *   onSubmit: (values: RoleFormData) => void | Promise<void>;
 * }}
 */
export default function RoleForm({ initialValues = ROLE_FORM_INITIAL_VALUES, onSubmit }) {
  const [loading, submit] = useLoadingAction(onSubmit);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'role';

  const form = useForm({
    initialValues,
    validate: {
      roleName: createValidator([required]),
      description: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)} className="pt-[10px]">
      <Tabs defaultValue={activeTab}>
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="role">{_t('Role')}</Tabs.Tab>
          <Tabs.Tab value="members">{_t('Members')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="role">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Role name')}
              input={<TextInput autoFocus placeholder={_t('Role name')} {...form.getInputProps('roleName')} />}
            />
            <FormRow
              label={_t('Description/hint text')}
              input={<Textarea placeholder={_t('Description')} {...form.getInputProps('description')} />}
            />
            <PermissionTable
              title={_t('Project permissions')}
              description={_t('Allows the user to perform operations on projects.')}
            >
              <PermissionRow
                slug="PROJECTS_VIEW_PROJECT"
                title={_t('View projects')}
                {...form.getInputProps('permissions.viewProjects')}
              />
              <PermissionRow
                slug="TIME_LOGS_CREATE_TIME_LOG"
                title={_t('Create and modify own time logs')}
                {...form.getInputProps('permissions.createTimeLogs')}
              />
              <PermissionRow
                slug="COMMENTS_CREATE_COMMENT"
                title={_t('Create comments and replies')}
                {...form.getInputProps('permissions.createComments')}
              />
              <PermissionRow
                slug="PROJECTS_UPLOAD_FILES"
                title={_t('Upload files')}
                {...form.getInputProps('permissions.uploadFiles')}
              />
            </PermissionTable>
            <PermissionTable
              title={_t('Managing Permissions')}
              description={_t('Allows user to modify parts of the system created by other users')}
            >
              <PermissionRow
                slug="COMMENTS_MANAGE_COMMENT"
                title={_t("Manage other's Comments")}
                {...form.getInputProps('permissions.manageOthersComments')}
              />
              <PermissionRow
                slug="TIME_LOGS_MANAGE_TIME_LOG"
                title={_t("Manage other's Time Logs")}
                {...form.getInputProps('permissions.manageOthersTimeLogs')}
              />
              <PermissionRow
                slug="PROJECTS_MANAGE_PROJECT"
                title={_t('Manage Projects')}
                {...form.getInputProps('permissions.manageProjects')}
              />
              <PermissionRow
                hideClient
                slug="FINANCE_OVERSEE_INTERNAL_COSTS"
                title={_t('Display Internal Costs')}
                {...form.getInputProps('permissions.overseeInternalCosts')}
              />
              <PermissionRow
                hideClient
                slug="FINANCE_MANAGE_FINANCE"
                title={_t('Manage Finance')}
                {...form.getInputProps('permissions.manageFinance')}
              />
              <PermissionRow
                hideClient
                slug="SETTINGS_MANAGE_SETTINGS"
                title={_t('Manage Settings')}
                {...form.getInputProps('permissions.manageSettings')}
              />
              <PermissionRow
                hideClient
                slug="SETTINGS_MANAGE_ROLE"
                title={_t('Manage Roles')}
                {...form.getInputProps('permissions.manageRoles')}
              />
            </PermissionTable>
          </Stack>
          <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
        </Tabs.Panel>
        <Tabs.Panel value="members">
          <FormRow
            className="pt-4"
            label={_t('Members (readonly)')}
            tooltipText={_t('The role of a member can be changed in the user profile.')}
            input={
              <UserSelect
                readOnly
                disabled
                multiple
                placeholder={_t('No members')}
                {...form.getInputProps('members')}
              />
            }
          />
        </Tabs.Panel>
      </Tabs>
    </form>
  );
}
