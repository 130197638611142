import { Box, Collapse, Group, Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import { _t } from 'lang';
import { useMemo, useState } from 'react';
import { useData } from '../../providers/DataProvider';
import ColorScale from 'utils/color-scale';
import RingIndicator from './RingIndicator';
import FinancialOverviewRow from './FinancialOverviewRow';
import useInternalCostUtilization from './use-internal-cost-utilization';

/**
 * Financial Overview Section in Add Cost Estimate Page.
 */
export default function FinancialOverviewSection() {
  const [opened, setOpened] = useState(true);
  const { data, canSeeInternalCosts } = useData();

  const internalCostUtilization = useInternalCostUtilization();

  const profitMarginScale = useMemo(() => ColorScale.fromCompact(['red', 1, 'yellow', 14, 'green']), []);
  const internalCostUtilizationScale = useMemo(() => ColorScale.fromCompact(['green', 94, 'yellow', 104, 'red']), []);

  if (!canSeeInternalCosts) {
    return null; // The section is useless if the user can't see internal costs.
  }

  return (
    <Stack spacing={16}>
      <GroupHeadline heading={_t('Financial overview')} opened={opened} setOpened={setOpened} />
      <Collapse in={opened}>
        <Box p={32} className="grid grid-cols-[1fr_auto] rounded-lg bg-white">
          <Stack spacing={64} pr={40}>
            <Stack spacing={0}>
              <FinancialOverviewRow label={_t('Revenue')} value={data.total} size="lg" />
              <FinancialOverviewRow label={_t('Profit')} value={data.profit} size="lg" />
            </Stack>
            <Stack spacing={0}>
              <FinancialOverviewRow label={_t('Total costs')} value={data.totalCosts} size="md" />
              <FinancialOverviewRow label={_t('Internal costs')} value={data.internalCosts} size="md" />
              <FinancialOverviewRow label={_t('External costs')} value={data.externalCosts} size="md" />
              <FinancialOverviewRow label={_t('Agency fees')} value={data.totalAgencyFees} size="md" />
              <FinancialOverviewRow label={_t('Discounts applied')} value={data.totalDiscounts} size="md" />
            </Stack>
          </Stack>
          <Group spacing={40} px={64}>
            <RingIndicator label={_t('Profit margin')} value={100 * data.profitMargin} scale={profitMarginScale} />
            <RingIndicator
              label={_t('Internal cost utilization')}
              value={100 * internalCostUtilization}
              scale={internalCostUtilizationScale}
            />
          </Group>
        </Box>
      </Collapse>
    </Stack>
  );
}
