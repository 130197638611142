import { Center, Group, RingProgress, Stack, Text } from '@mantine/core';
import { round } from 'lodash';
import { useMemo } from 'react';
import ColorScale from 'utils/color-scale'; // eslint-disable-line no-unused-vars

/**
 * Centered label for the Ring Indicator.
 */
function RingIndicatorLabel({ value }) {
  const roundedValue = useMemo(() => {
    if (value === Infinity) {
      return '∞';
    }

    if (Math.abs(value) >= 10) {
      return round(value);
    }

    return round(value, 1).toFixed(1);
  }, [value]);

  return (
    <Center>
      <Group spacing={8} align="center">
        <Text fz={64} lh={1.12}>
          {roundedValue}
        </Text>
        <Text fz={40} lh={1.2} weight={300}>
          %
        </Text>
      </Group>
    </Center>
  );
}

/**
 * Ring Indicator in the financial overview section.
 *
 * @param {{
 *   label: string,
 *   value: number,
 *   scale: ColorScale;
 * }}
 */
export default function RingIndicator({ label, value, scale }) {
  const color = useMemo(() => scale.getColor(value), [value]);
  const sections = useMemo(() => [{ value, color }], [value, color]);

  return (
    <Center>
      <Stack spacing={24}>
        <Text align="center" fz={22} lh={1.27}>
          {label}
        </Text>
        <RingProgress size={256} sections={sections} roundCaps label={<RingIndicatorLabel value={value} />} />
      </Stack>
    </Center>
  );
}
