import PageHeading from '../../../../layouts/dashboard-layout/headers/PageHeading';
import { Box, Breadcrumbs, Button, Flex } from '@mantine/core';
import { Link, useSearchParams } from 'react-router-dom';
import { FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from '../../../../routes/paths';
import { _t } from '../../../../lang';
import Alert from 'components/Alert';
import { useEffect, useState } from 'react';
import ClientSelect from '../../../../components/selects/ClientSelect';
import CostEstimateWizard from './components/CostEstimateWizard';
import ProjectBillingTypeProjectIcon from '../../../../components/icons/ProjectBillingTypeProjectIcon';
import ProjectBillingTypeRetainerIcon from '../../../../components/icons/ProjectBillingTypeRetainerIcon';
import LinkWithConfirm from '../../../../components/navigate/LinkWithConfirm';
import { useClient } from 'providers/client/ClientProvider';
import { noop } from 'lodash';
import { useSubmit } from './providers/SubmitProvider';

/**
 * Renders a component for selecting the type of cost estimate on a new page.
 */
export default function AddCostEstimatePageChooseType() {
  const [searchParams] = useSearchParams();
  const [selectedClientId, setSelectedClientId] = useState(() => searchParams.get('clientId') || null);
  const { setClientId, clientId } = useClient();
  const { save } = useSubmit();

  useEffect(() => {
    if (clientId) {
      save({ isDraft: true });
    }
  }, [clientId]);

  return (
    <div>
      {/* Header / Edit */}
      <PageHeading
        heading={_t('Add new cost estimate')}
        breadcrumbs={
          <Breadcrumbs>
            <Link to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}>{_t('Finance')}</Link>
          </Breadcrumbs>
        }
      />

      {/* Content */}
      <Box pt={24} pb={16}>
        <Alert
          severity="notification"
          primaryText={
            <>
              For the desired client, choose the billing type for the projects you’re creating a cost estimate for.
              <br /> Once selected, the billing type cannot be changed!
            </>
          }
        />
      </Box>

      <Flex gap={16} direction="column" align="center" className="self-stretch">
        <Box py={16} className="mx-auto w-full max-w-[484px]">
          <ClientSelect placeholder={_t('Select Client')} value={selectedClientId} onChange={setSelectedClientId} />
        </Box>

        <Flex pt={16} pb={64} justify="center" align="flex-start" gap="0px 16px" wrap="wrap">
          <CostEstimateWizard
            disabled={!selectedClientId}
            icon={<ProjectBillingTypeProjectIcon />}
            title={_t('Project')}
            description={_t(
              'A predetermined cost agreed upon before starting a project, based on a clearly defined scope and specific deliverables, or a pricing model that charges for the actual hours worked on tasks within a specified timeframe.'
            )}
            type={'project'}
            onClick={() => setClientId(Number(selectedClientId))}
            loading={!!clientId}
          />
          <CostEstimateWizard
            icon={<ProjectBillingTypeRetainerIcon />}
            title={_t('Retainer')}
            description={_t(
              'A fixed monthly fee covers a set number of hours for each role as defined in the agreement. Additional work or hours outside the agreed scope may be invoiced separately.'
            )}
            type={'retainer'}
            onClick={noop}
          />
        </Flex>
      </Flex>

      <Flex height={36} py={16} justify="flex-end">
        <Button
          w={80}
          h={36}
          variant="link"
          component={LinkWithConfirm}
          to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}
          confirmTitle={_t('Cancel')}
          skipConfirm={true}
        >
          {_t('Cancel')}
        </Button>
      </Flex>
    </div>
  );
}
