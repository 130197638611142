import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { useMemo } from 'react';
import { useData } from '../../providers/DataProvider';
import { _t } from 'lang';
import { Breadcrumbs, Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useClient } from 'providers/client/ClientProvider';
import { FINANCE_DETAIL_COST_ESTIMATE_PDF_PREVIEW_PAGE_PATH, FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from 'routes/paths';
import ExportPdfIcon from 'components/icons/ExportPdfIcon';

/**
 * Heading section for cost estimate page.
 */
export default function HeadingSection() {
  const { data } = useData();
  const { client, clientId } = useClient();

  const suffix = useMemo(() => (data.isDraft ? _t('(draft)') : ''), [data.isDraft]);

  const heading = useMemo(
    () => (data.costEstimateId ? `${data.fullName} ${suffix}`.trim() : _t('New Cost Estimate')),
    [data.costEstimateId, data.fullName, suffix]
  );

  const clientFinanceDetailLink = useMemo(
    () => `${FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}?clientId=${clientId}`,
    [clientId]
  );

  const pdfLink = useMemo(
    () => FINANCE_DETAIL_COST_ESTIMATE_PDF_PREVIEW_PAGE_PATH.insert({ costEstimateId: data.costEstimateId }),
    [data.costEstimateId]
  );

  return (
    <PageHeading
      heading={heading}
      breadcrumbs={
        <Breadcrumbs>
          <Link to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}>{_t('Finance')}</Link>
          {client && <Link to={clientFinanceDetailLink}>{client.client_name}</Link>}
        </Breadcrumbs>
      }
      actionButton={
        <Button component={Link} to={pdfLink} target="_blank" variant="secondary" leftIcon={<ExportPdfIcon />}>
          {_t('Download preview')}
        </Button>
      }
    />
  );
}
