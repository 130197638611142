/** @typedef {import('./CostEstimateRowData').CostEstimateRowData} CostEstimateRowData */
/** @typedef {import('./DiscountData').DiscountData} DiscountData */
/** @typedef {import('./AgencyFeeData').AgencyFeeData} AgencyFeeData */

import { groupBy, mapValues, round, sumBy } from 'lodash';

/**
 * Creates a new instance of CostEstimateSectionData.
 *
 * @param {{
 *   name: string,
 *   rows: CostEstimateRowData[],
 *   isInHouse: boolean,
 *   discount: DiscountData | undefined,
 *   agencyFee: AgencyFeeData | undefined,
 * }}
 */
export function createCostEstimateSectionData({ name = '', rows = [], isInHouse = true, discount, agencyFee } = {}) {
  // Price before discount.
  const subtotal = round(sumBy(rows, 'price'), 2);

  // Price after discount.
  let total = subtotal;
  let discountAmount = 0;
  let agencyFeeAmount = 0;

  if (discount) {
    total = discount.apply(total);
    discountAmount = round(subtotal - total, 2);
  } else if (agencyFee) {
    total = agencyFee.apply(total);
    agencyFeeAmount = round(total - subtotal, 2);
  }

  // Sum of external costs.
  const externalCosts = round(sumBy(rows, 'externalCosts'), 2);

  // Sum of internal costs.
  const internalCosts = round(sumBy(rows, 'cost'), 2);

  // Map of hours by job position.
  const hoursByPosition = mapValues(groupBy(rows, 'jobPositionId'), (rows) => sumBy(rows, 'number'));

  return Object.freeze({
    name,
    rows,
    isInHouse,
    discount,
    agencyFee,
    subtotal,
    total,
    discountAmount,
    agencyFeeAmount,
    externalCosts,
    internalCosts,
    hoursByPosition,
  });
}

/** @typedef {ReturnType<typeof createCostEstimateSectionData>} CostEstimateSectionData */
