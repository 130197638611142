import { Flex, Group } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import PriceDisplay from 'components/PriceDisplay';
import panic from 'errors/Panic';
import { sum, uniq, values } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useData } from '../../providers/DataProvider';
import ProgressGroup from 'components/progress/ProgressGroup';
import { _t } from 'lang';
import { useCostEstimatePartData } from './CostEstimatePart';
import { CUSTOM_JOB_POSITION_ITEM_ID } from '../../data/CostEstimateRowData';

/**
 * A component that provides an overview of internal costs.
 */
export default function InternalCostOverview() {
  const { getAction } = useApi();
  const { data } = useCostEstimatePartData();
  const [actualCost, setActualCost] = useState(0);
  const [actualHours, setActualHours] = useState(0);
  const { data: costEstimateData, canSeeInternalCosts } = useData();

  const allocatedHours = useMemo(
    () => sum(values(data.hoursByPosition)) - (data.hoursByPosition[CUSTOM_JOB_POSITION_ITEM_ID] ?? 0),
    [data.hoursByPosition]
  );

  const tasks = useMemo(
    () => uniq(data.projects.filter(({ allTasks }) => !allTasks).flatMap(({ tasks }) => tasks)),
    [data.projects]
  );

  const projects = useMemo(
    () => data.projects.filter(({ allTasks }) => allTasks).map(({ projectId }) => projectId),
    [data.projects]
  );

  useEffect(() => {
    const getSummary = getAction('TimeLogGetInternalCostsSummaryAction');

    getSummary({ query: { tasks, projects } })
      .then(({ total_cost, total_hours }) => {
        setActualCost(total_cost);
        setActualHours(total_hours);
      })
      .catch(panic);
  }, [tasks, projects]);

  const actual = canSeeInternalCosts ? actualCost : actualHours;
  const allocated = canSeeInternalCosts ? data.internalCosts : allocatedHours;
  const currency = canSeeInternalCosts ? costEstimateData.currency : 'hrs.';

  return (
    <Group
      h={68}
      align="center"
      className="grid grid-cols-[1fr_140px_140px_256px] items-center gap-8 rounded-lg bg-white"
      pr={72}
      pl={32}
    >
      <h3 className="text-[22px]">
        {canSeeInternalCosts ? _t('Internal cost overview') : _t('Internal hours overview')}
      </h3>
      <Flex direction="column" align="flex-end" w={144}>
        <small className="text-neutral-500">
          {canSeeInternalCosts ? _t('Allocated costs') : _t('Allocated hours')}
        </small>
        <PriceDisplay value={allocated} currency={currency} />
      </Flex>
      <Flex direction="column" align="flex-end" w={144}>
        <small className="text-neutral-500">{_t('Actual utilization')}</small>
        <PriceDisplay value={actual} currency={currency} />
      </Flex>
      <ProgressGroup part={actual} total={allocated} variant="positive" barHeight={8} />
    </Group>
  );
}
