/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/CostEstimatePartData').CostEstimatePartData} CostEstimatePartData */

import { Group } from '@mantine/core';
import PriceDisplay from 'components/PriceDisplay';
import ProgressGroup from 'components/progress/ProgressGroup';

/**
 * A summary of hours per position.
 *
 * @param {{
 *   data: CostEstimatePartData,
 *   name: string,
 *   jobPositionId: number,
 *   spentHours: number,
 * }}
 */
export default function HoursSummaryJobPosition({ data, name, jobPositionId, spentHours }) {
  const allocatedHours = data.hoursByPosition[jobPositionId] ?? 0;

  return (
    <Group h={48} align="center" className="grid grid-cols-[1fr_112px_112px_112px] items-center gap-4" pr={32}>
      <span>{name}</span>
      <PriceDisplay className="text-right" value={allocatedHours} currency="hrs." />
      <PriceDisplay className="text-right" value={spentHours} currency="hrs." />
      <ProgressGroup part={spentHours} total={allocatedHours} variant="positive" />
    </Group>
  );
}
